import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton } from '../../components';

// Carousel
import Carousel from 'react-multi-carousel';

import { getCarouselResponsiveSettings, getContentWidth, reviews } from './AboutPage.shared';
import css from './AboutPage.module.css';
import quoteSVG from '../../assets/fa_quote-left.svg';

const AUTOPLAY_SPEED = 5000;
const TRANSITION_DURATION = 800;

// Define carousel settings
const carouselSettings = {
  infinite: true,
  swipeable: false,
  draggable: false,
  arrows: false,
  autoPlay: true,
  autoPlaySpeed: AUTOPLAY_SPEED,
  transitionDuration: TRANSITION_DURATION,
  ...getCarouselResponsiveSettings(),
};

const SlidePrevButton = ({ onClick }) => {
  return (
    <InlineTextButton className={css.slideButton} onClick={() => onClick()}>
      <FormattedMessage id="AboutPage.SectionReviews.slidePrev" />
    </InlineTextButton>
  );
};

const SlideNextButton = ({ onClick }) => {
  return (
    <InlineTextButton className={css.slideButton} onClick={() => onClick()}>
      <FormattedMessage id="AboutPage.SectionReviews.slideNext" />
    </InlineTextButton>
  );
};

const Review = props => {
  const { review } = props;
  const { author, content } = review;
  return (
    <div className={css.review}>
      <img src={quoteSVG}/>
      <p className={css.reviewText}>{content}</p>
      <span className={css.reviewAuthor}>{author}</span>
    </div>
  );
};

export const SectionReviews = props => {
  const { sectionId, isHomePage } = props;

  const carouselRef = useRef(null);
  const contentWidth = getContentWidth();

  useEffect(() => {
    function handleKeyDown(e) {
      // console.log(e.keyCode);
      if (e.keyCode == 39) {
        carouselRef?.current?.next();
      }
      if (e.keyCode == 37) {
        carouselRef?.current?.previous();
      }
    }
    document.addEventListener('keydown', handleKeyDown);
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const sectionWidthMaybe = contentWidth
    ? {
        style: {
          width: contentWidth,
        },
      }
    : {};

  return (
    <div
      id={sectionId}
      className={!isHomePage ? css.sectionReviews : css.landingSectionReviews}
      {...sectionWidthMaybe}
    >
      <div className={css.reviewsCarouselWrapper}>
        <h2 className={css.reviewsTitle}>
          <FormattedMessage id="AboutPage.SectionReviews.title" />
        </h2>
        <Carousel
          sliderClass={css.reviewsCarouselSlider}
          ref={el => (carouselRef.current = el)}
          {...carouselSettings}
        >
          {reviews.map(t => (
            <Review key={t.id} review={t} />
          ))}
        </Carousel>
        <div className={css.slideButtons}>
          <FormattedMessage
            id="AboutPage.SectionReviews.slideButtons"
            values={{
              prev: <SlidePrevButton onClick={() => carouselRef?.current?.previous()} />,
              next: <SlideNextButton onClick={() => carouselRef?.current?.next()} />,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SectionReviews;
