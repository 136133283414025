import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading } from '../../components';

import searchImage from './images/search-image.png';
import applyImage from './images/apply-image.png';
import discoverImage from './images/discover-image.png';

import css from './AboutPage.module.css';
import { useAnimatedInView } from './AboutPage.shared';
import classNames from 'classnames';

const smallBoxItem = (imgSrc, headingTranslationId, textTranslationId, AnimatedDiv) => (
  <div className={css.howItWorksSmallBox}>
    <div className={css.imgContent}>
      <AnimatedDiv delay={1.0}>
        <img className={classNames(css.howItWorksSmallBoxImage)} src={imgSrc} />
      </AnimatedDiv>
    </div>

    <div className={css.howItWorksSmallBoxWrapper}>
      <AnimatedDiv delay={1.5}>
        <Heading className={css.howItWorksSmallHeading} as="h5">
          <FormattedMessage id={headingTranslationId} />
        </Heading>
      </AnimatedDiv>
      <AnimatedDiv delay={2}>
        <p className={css.howItWorksSmallBoxText}>
          <FormattedMessage id={textTranslationId} />
        </p>
      </AnimatedDiv>
    </div>
  </div>
);

export const SectionHowItWorks = props => {
  const { sectionId, isMobileLayout, isHomePage } = props;

  const { ref, AnimatedDiv } = useAnimatedInView({
    animate: !isMobileLayout,
    threshold: 0.7,
    triggerOnce: true,
  });

  return (
    <div
      id={sectionId}
      className={!isHomePage ? css.sectionHowItWorks : css.sectionLandingPageHowItWorks}
    >
      <div className={css.howItWorksBorderBox} ref={ref}>
        <AnimatedDiv>
          <p className={css.howItWorksSubTitle}>
            <FormattedMessage id="AboutPage.SectionHowItWorks.subTitle" />
          </p>
        </AnimatedDiv>
        <AnimatedDiv delay={0.5}>
          <Heading className={css.howItWorksTitle} as="h2">
            <FormattedMessage id="AboutPage.SectionHowItWorks.title" />
          </Heading>
        </AnimatedDiv>
      </div>

      <div className={css.howItWorksSmallBoxes}>
        {smallBoxItem(
          searchImage,
          'AboutPage.SectionHowItWorks.firstHeading',
          'AboutPage.SectionHowItWorks.firstText',
          AnimatedDiv,
        )}
        {smallBoxItem(
          applyImage,
          'AboutPage.SectionHowItWorks.secondHeading',
          'AboutPage.SectionHowItWorks.secondText',
          AnimatedDiv,
        )}
        {smallBoxItem(
          discoverImage,
          'AboutPage.SectionHowItWorks.thirdHeading',
          'AboutPage.SectionHowItWorks.thirdText',
          AnimatedDiv,
        )}
      </div>
    </div>
  );
};

export default SectionHowItWorks;
